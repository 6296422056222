<template>
  <b-card bg-variant="main" class="schedule-card">
    <template #header>
      <span v-html="$t('schedule-title')"></span>
    </template>
    <div class="list-group list-group-flush">
      <ScheduleItem
        v-for="(item, index) in schedule.items"
        :isCurrent="isCurrent(index)"
        :key="index"
        :item="item"
      />
    </div>
  </b-card>
</template>
<script>
import ScheduleItem from "@/components/landing/ScheduleItem.vue";
export default {
  props: ["selected", "currentIndex"],
  components: {
    ScheduleItem,
  },
  async created() {
    await this.doFetchAll();
  },
  methods: {
    async doFetchAll() {
      await this.$store.dispatch("Schedule/fetchAllParsed");
    },
    isCurrent(index) {
      return index == this.currentIndex;
    },
  },
  computed: {
    // events() {
    //   return this.$store.getters["Auth/currentUser"]?.event_sessions;
    // },
    schedule() {
      return this.$store.getters["Schedule/byTitle"](this.selected);
    },
    schedules() {
      return this.$store.state.Schedule.all;
    },
  },
};
</script>
<style lang="scss">
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);

.schedule-card {
  .card-body {
    padding-left: 0px;
    padding-right: 0px;
    .card-title {
      margin-left: 15x;
    }
  }
}
</style>
