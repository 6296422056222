<template>
  <b-container :class="classes" fluid>
    <b-row>
      <b-col
        v-if="leftColumnActive"
        cols="12"
        :md="leftColumnSizeMedium"
        :xl="leftColumnSizeLarge"
        :offset-md="leftColumnOffsetMedium"
        :offset-xl="leftColumnOffsetLarge"
      >
        <Intro v-if="introActive" />
        <Schedule
          v-if="scheduleActive"
          :selected="content.intro.schedule.selected"
        />
      </b-col>
      <b-col
        cols="12"
        :md="middleColumnSizeMedium"
        :xl="middleColumnSizeLarge"
        :offset-md="middleColumnOffsetMedium"
        :offset-xl="middleColumnOffsetLarge"
      >
        <Items :blocks="content.blocks" />
      </b-col>
      <b-col
        v-if="rightColumnActive"
        cols="12"
        :md="rightColumnSizeMedium"
        :xl="rightColumnSizeLarge"
      >
        <Chat
          v-if="interactionActive"
          :type="content.interaction.chat.type[locale]"
          :code="content.interaction.chat.id[locale]"
          :roomCode="content.interaction.chat.roomCode[locale]"
        />
        <!-- <OnlineUsers
          v-if="content.interaction.type[locale] == 'online-users'"
        /> -->
        <hr v-if="interactionActive && socialWallActive" />
        <OnlineUsers v-if="onlineUsersActive" />
        <SocialWall v-if="socialWallActive" :topic="socialWallTopic" />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Intro from "@/components/landing/Intro.vue";
import Items from "@/components/landing/Items.vue";
import Chat from "@/components/chat/Chat.vue";
import Schedule from "@/components/landing/Schedule.vue";
import SocialWall from "@/components/landing/SocialWall.vue";
import OnlineUsers from "@/components/landing/OnlineUsers.vue";

export default {
  name: "Dashboard",
  components: {
    Intro,
    Items,
    Chat,
    Schedule,
    SocialWall,
    OnlineUsers,
  },
  async created() {
    await this.$store.dispatch("Dash/fetchAll");
  },
  computed: {
    classes() {
      return this.isHeaderSticky ? "dashboard" : "dashboard margin-header";
    },
    isHeaderSticky() {
      return this.$store.getters["Styling/stickyHeader"];
    },
    leftColumnSizeMedium() {
      return 2;
    },
    leftColumnSizeLarge() {
      return 2;
    },
    rightColumnSizeMedium() {
      return 3;
    },
    rightColumnSizeLarge() {
      return 3;
    },
    middleColumnSizeMedium() {
      if (!this.rightColumnActive) {
        return 8;
      }
      return 7;
    },
    middleColumnSizeLarge() {
      if (!this.rightColumnActive) {
        return 8;
      }
      return 7;
    },
    middleColumnOffsetMedium() {
      if (!this.leftColumnActive && !this.rightColumnActive) {
        return 2;
      }
      if (!this.leftColumnActive) {
        return 1;
      }
      return 0;
    },
    middleColumnOffsetLarge() {
      if (!this.leftColumnActive && !this.rightColumnActive) {
        return 2;
      }
      if (!this.leftColumnActive) {
        return 1;
      }
      return 0;
    },
    leftColumnOffsetMedium() {
      if (!this.rightColumnActive) {
        return 1;
      }
      return 0;
    },
    leftColumnOffsetLarge() {
      if (!this.rightColumnActive) {
        return 1;
      }
      return 0;
    },
    locale() {
      return this.$root.$i18n.locale;
    },
    content() {
      return this.$store.state.Dash.content;
    },
    interactionActive() {
      return this.content.interaction?.active;
    },
    chatActive() {
      return this.content.interaction?.chat?.active;
    },
    introActive() {
      return this.content.intro?.active;
    },
    schedule() {
      return this.$store.getters["Schedule/byTitle"](this.selected);
    },
    scheduleActive() {
      return this.content.intro?.schedule?.active;
    },
    socialWallActive() {
      return this.content.interaction.social?.active;
    },
    socialWallTopic() {
      return this.content.interaction.social?.topic;
    },
    onlineUsersActive() {
      return this.content.interaction.onlineusers?.active;
    },
    leftColumnActive() {
      return this.introActive || this.scheduleActive;
    },
    rightColumnActive() {
      return this.interactionActive;
    },
    minimizeMiddleCol() {
      return this.rightColumnActive && this.introActive;
    },
    enlargeMiddleCol() {
      return !this.rightColumnActive && !this.introActive;
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard {
  flex-grow: 1;
  padding-bottom: 50px;
}
</style>
